.layout-config {
    // position: fixed;
    // top: 0;
    // padding: 0;
    // right: 0;
    // width: 20rem;
    // z-index: 999;
    // height: 100vh;
    // transform: translateX(100%);
    // transition: transform $transitionDuration;
    // backface-visibility: hidden;
    // box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;
    // color: var(--text-color);
    // background-color: var(--surface-overlay);
    // border-top-left-radius: 12px;
    // border-bottom-left-radius: 12px;

    // &.layout-config-active {
    //     transform: translateX(0);
    // }

    .layout-config-button {
        display: block;
        position: absolute;
        width: 52px;
        height: 52px;
        line-height: 52px;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        text-align: center;
        top: 230px;
        left: -52px;
        z-index: -1;
        overflow: hidden;
        cursor: pointer;
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        transition: background-color $transitionDuration;

        i {
            font-size: 32px;
            line-height: inherit;
            cursor: pointer;
            transform: rotate(0deg);
            transition: color $transitionDuration, transform 1s;
        }
    }

    .layout-config-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: 1;
    }

    .layout-config-content {
        position: relative;
        overflow: auto;
        height: calc(100vh - 5rem);
        padding: 2rem;
    }

    .config-scale {
        display: flex;
        align-items: center;
        margin: 1rem 0 2rem 0;

        .p-button {
            margin-right: .5rem;
        }

        i {
            margin-right: .5rem;
            font-size: .75rem;
            color: var(--text-color-secondary);

            &.scale-active {
                font-size: 1.25rem;
                color: var(--primary-color);
            }
        }
    }

    .free-themes {
        img {
            width: 2rem;
            border-radius: 4px;
            transition: transform .2s;
            display: block;

            &:hover {
                transform: scale(1.1);
            }
        }

        span {
            font-size: .75rem;
            margin-top: .25rem;
        }
    }
}
